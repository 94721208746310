@import 'variables.scss';

html {
  font-size: 14px;
}

body {
  margin: 0;
  font-family: sans-serif;

  #root {
    position: absolute;
    height: calc(100% - #{$margin-top});
    width: 100%;
  }

  .FlexColumn {
    display: flex;
    flex-flow: column wrap;
    margin-top: $margin-top;
  }

  .MuiContainer-root {
    width: inherit;
  }
}

a {
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6, p, div {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); /* iOS */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

main {
  // Make space at the bottom so the last bits are not covered by the bottom bar.
  padding-bottom: 65px;
}
