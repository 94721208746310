// Sizes
$basic-margin: 20px;

$header-height: 50px;
$footer-height: 56px;

$margin-top: $header-height + $basic-margin;

// Colors
$base-light-color: #eeeeee;
$base-dark-color: #000000;
$main-color: #3e4384;
$success: #43843e;
